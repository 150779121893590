import PlanCard from "./PlanCard";

export default function Pricing() {
  return (
    <div id="pricing" className="flex flex-col items-center bg-secondary p-4">
      <div className="mb-2 mt-12 text-center">
        <h1 className="mb-4 text-4xl font-black text-white">Pricing</h1>
        <p className="text-lg text-white bg-black rounded-xl p-2">
         <em>Additional Stylist + £100, Boho/Goddess version of all styles attract £0, <br /> Knotless version of all styles attract £0, <br /> Hair Extensions costs £20, Children's Hair - £20, Men £30</em>    
        </p>
      </div>
      <div className="flex flex-col gap-8 p-10 xl:flex-row">
        <PlanCard 
        color="#F0F0F0"
        name="Braids"
        price="£100"
        features={[
          <strong>Traditional Braids</strong>, 
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>French Curl Braids</strong>,
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Extra small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>Braids without Extensions</strong>,
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>Crotchet Braids £0</strong>
        ]}
        btnText="Order Now"
        />
        <PlanCard 
        color="#FFEEAD"
        name="Twists"
        price="£80"
        features={[
          <strong>Passion Twist</strong>, 
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>Kinky Twist</strong>,
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>Twist without Extension</strong>,
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
        ]}
        btnText="Order Now"
        />
        <PlanCard 
        color="#FFB5BA"
        name="Cornrows"
        price="£100"
        features={[
          <strong>Cornrows with Extensions</strong>,
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          <strong>Cornrows without Extension</strong>,
          "Jumbo (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
          "Large (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Medium (bra-lgth £0, wst-lgth £0, bum-lgth £0)", 
          "Small (bra-lgth £0, wst-lgth £0, bum-lgth £0)",
        ]}
        btnText="Order Now"
        />
        <PlanCard 
        color="#78E3FC"
        name="Treatments"
        description=<em>Wash, Treatments, Styling</em>
        price="£100"
        features={[
          "Hair Wash and Conditioning £0", 
          "Deep Conditioning / Moisture Treatment £0", 
          "Scalp Detox £0", 
          "Protein Treatment £0", 
          "Mixed Treatment £0",
          "Olaplex Treatment £0",
          "Botox Treatment £0",
          "Avalon Texture release Treatment £0",
          "Hair Trim £0"
        ]}
        btnText="Order Now"
        />
      </div>
    </div>
  )
}