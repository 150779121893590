import React from 'react';

import tresses3 from '../assets/tresses3.jpg';
import tresses6 from '../assets/tresses6.jpg';
import tresses7 from '../assets/tresses7.jpg';
import tresses8 from '../assets/tresses8.jpg';
import bwext1 from '../assets/bwext1.jpg';
import bwext2 from '../assets/bwext2.jpg';
import croc from '../assets/croc.jpg';
import croc1 from '../assets/croc1.jpg';
import french1 from '../assets/french1.jpg';
import french2 from '../assets/french2.png';
import jumbo2 from '../assets/jumbo2.jpg';
import men1 from '../assets/men1.jpg';
import men2 from '../assets/men2.jpg';
import knotless from '../assets/knotless.jpg';
import knotess2 from '../assets/knotless2.jpg';
import weaves from '../assets/weaves.jpg';
import weaves2 from '../assets/weaves2.jpg';
import weaves3 from '../assets/weaves3.jpg';
import olaplex1 from '../assets/olaplex1.jpg';
import olaplex2 from '../assets/olaplex2.jpg';
import Slider from './Slider';


function Portfolio() {
  const images = [tresses3, tresses6, tresses7, tresses8, 
  bwext1, bwext2, croc, croc1, french1, french2, jumbo2, men1, men2, 
  knotless, knotess2, weaves, weaves2, weaves3, olaplex1, olaplex2]

  return (
    <div id='portfolio'>
      <Slider 
      images={images}
      title="You like what you see?"
      />
    </div>
  )
}

export default Portfolio