import { useState, useEffect } from "react";

function Slider({images, title}) {
    const [slideIndex, setSlideIndex] = useState(0);
    const [activeSlide, setActiveSlide] = useState(images[slideIndex]);

    function setSlider(number) {
        console.log('number', number);
        console.log('index', slideIndex);

        if (slideIndex === 0 && number === -1) {
            setSlideIndex(images.length - 1);
            return
        }

        if (slideIndex === images.length - 1 && number === 1) {
            setSlideIndex(0);
            return
        }

        let newNumber = slideIndex + number;
        setSlideIndex(newNumber);
        return
    }

    useEffect(() => {
      setActiveSlide(images[slideIndex])
    }, [slideIndex])
    

    return (
         <div id="slider" className="flex flex-col items-center justify-center bg-primary p-8 h-screen" >
         <h1 className="text-2xl text-slate-100 font-bold mb-4">{title}</h1>
         {images && 
         <div className="slider-container relative flex flex-col items-center justify-center w-screen h-full ">
            <div className="slider-img max-w-screen-md w-10/12 rounded-md overflow-hidden shadow-xl flex justify-center items-center ">
                <img 
                className="object-cover h-full w-full"
                src={activeSlide}
                 />
            </div>
            <a className="btn-prev absolute  left-5 sm:left-10 bg-white p-2 rounded-full w-10 h-10 flex justify-center items-center hover:cursor-pointer shadow-lg"
            onClick={() => setSlider(-1)}
            >&lt;</a>
            <a className="btn-next absolute right-5 sm:right-10 bg-white p-2 rounded-full w-10 h-10 flex justify-center items-center hover:cursor-pointer shadow-lg"
            onClick={() => setSlider(1)}
            >&gt;</a>
         </div>
            
         }
         </div>
    );
}

export default Slider;