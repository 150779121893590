import React from 'react';
import styled from "styled-components";
import Title from './Title';

function Skills() {
  const skillsData = [
    {
      name: "Braids",
      amount: 85,
    },
    {
      name: "Weaves",
      amount: 80,
    },
    {
      name: "Twists",
      amount: 85,
    },
    {
      name: "Locs",
      amount: 80,
    },
    {
      name: "Treatments",
      amount: 90,
    },
  ];

  return (
    <Section id='skills'>
      <Title value="skills" />
      <div className="skills_title">
        <p>Our Skills</p>
        <h2>Check out our awesome skills</h2>
      </div>
      <div className="skills">
        <div className="skills_bars">
          {
            skillsData.map(({ name, amount }) => {
              return (
                <div className="skills_bars_bar" key={name}>
                  <div className="container">
                    <progress value={amount} max ="100" />
                    <span>{name}</span>
                  </div>
                  <h3>{amount}%</h3>
                </div>
              );
            })
          }
        </div>
        <div className="skills__content">
          <p className="title">
            Tresses and Treatments
          </p>
          <p className="description">
            Our team of skilled professionals are highly experienced but also genuinely passionate about what they do. 
            We take the time to listen to your desires, 
            to create stunning braids and provide hair treatments.
          </p>
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
min-height: 100vh;
height: 140vh;
background-color: var(--secondary-color);

.sideTitle {
  h1 {
    color: #fff;
    font-size: 9rem;
    z-index: 2;
  }
}

.skills_title {
  padding: 6rem 10rem;
  p {
    text-transform; uppercase;
    letter-spacing: 0.2rem;
    color: var(--primary-color);
  }
  h2 {
    color: #fff;
    font-size: 2rem;
  }
}
.skills {
    display: flex;
    padding: 0 4rem 0 15rem;
    gap: 10rem;
    &_bars {
      transform: rotate(-90deg);
      width: max-content;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      &_bar {
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        .container {
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          span {
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            color: var(--primary-color);
          }
          progress {
            width: 30rem;
            -webkit-appearance: none;
            appearance: none;
            &::-webkit-progress-bar {
              height: 3rem;
              background-color: var(--primary-color);
              border-radius: 20px;
            }
            &::-webkit-progress-value {
              background-color: white; 
            }
          }
        }
        h3 {
          transform: rotate(90deg);
          color: #fff;
          font-size: 1.5rem;
        }
      }
    }
      &__content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      color: white;
      z-index: 2;
      .title {
        font-weight: bold;
      }
    }
  }

    @media screen and (min-width: 280px) and (max-width: 1080px) {
      overflow-x: hidden;
      padding: 2rem 0;
      .background {
        display: none;
      }
      .skills_title {
        padding: 2rem;
        text-align: center;
        h2 {
          font-size: 1.5rem;
        }
      }
      .skills {
        padding: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &_bars {
          padding: 0.2rem;
          gap: 0rem;
          align-items: center;
          justify-content: center;
          &_bar {
            .container {
              gap: 1rem;
              progress {
                width: 12rem;
                height: 0.5rem;
                &::-webkit-progress-bar {
                  height: 0.3rem;
                }
              }
            }
            h3 {
              font-size: 1rem;
            }
          }
        }
        &__content {
            padding: 0 1.5rem;
          }
      }
    }
`;

export default Skills