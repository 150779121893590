export const homeAnimation = {
    hidden: { x: -400, opacity: 0 },
    show: { x: 0, opacity: 1 },
}; 

export const homeInfoAnimation = {
    hidden: { x: 100, opacity: 0 },
    show: { x: 0, opacity: 1 },
};

export const navAnimation = {
    hidden: { y: -20, opacity: 0 },
    show: { y: 1, opacity: 1 },
};

export const servicesAnimation = {
    hidden: { y: 200, opacity: 0 },
    show: { y: 0, opacity: 1 },
};