import React from 'react';
import styled from "styled-components";
import Title from './Title';

function Contact() {
  return (
    <Section id='contact'>
      <Title value="contact" />
      <div className="contact">
        <div className="contact_title">
          <p>Stay in touch with us</p>
          <h2>Quick Contact</h2>
        </div>
        <div className="contact_data">
          <div className="contact_data_description">
            <h4>Just to say Hi !!</h4>
            <p>
              If you have any questions simply use the following contact details.
            </p>
            <p>
              Our Online Presence in the next level. We are in the business of Bulk SMS, Digital Marketing.
            </p>
            <div>
              <p>
                <strong>Address:</strong> 22 Whitworth Road, <br /> Postcode: SE18 3QB.
              </p>
              <p>
               <strong>Email:</strong> tressesandtreatments@gmail.com
              </p>
              <p>
               <strong>Tel:</strong> +44 7392388138
              </p>
            </div>
          </div>
          <div className="contact_data_form">
            <input type="text" placeholder='name' />
            <input type="email" placeholder='email' />
            <textarea placeholder='message' ></textarea>
            <button type="submit">
              <a href="mailto:tressesandtreatments@gmail.com">
              <p>Send Message</p>
            </a>
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
min-height: 100vh;
.contact {
  color: var(--primary-color);
  margin: 0 18rem;
  &_title {
    margin: 6rem 0 2rem 0;
    p {
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      color: var(--primary-color);
    }
    h2 {
      font-size: 2rem;
      color: var(--secondary-color);
    }
  }
  &_data {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 4rem;
    &_description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h4 {
        font-size: 1.5rem;
      }
      p {
        text-align: justify;
        margin-top: 20px;
      }
      div {
        p {
          strong {
            text-transform: uppercase;
          }
        }
      }
    }
    &_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      input,
      textarea {
        border: none;
        border-bottom: 0.1rem solid var(--secondary-color);
        width: 100%;
        color: var(--secondary-color);
        padding-bottom: 0.7rem;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: var(--secondary-color);
        }
      }
      textarea {
        width: 100%;
        height: 50%;
        resize: none;
      }
      button {
        width: 100%;
        background-color: var(--secondary-color);
        border: 0.1rem solid var(--secondary-color);
        height: 3rem;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        a {
          text-decoration: none;
          p {
          color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
.contact {
      margin: 1rem;
      padding: 0 1rem;
      &_title {
        margin: 0;
        text-align: center;
        p {
          font-size: 0.8rem;
        }
        h2 {
          font-size: 1.3rem;
        }
      }
      &_data {
        grid-template-columns: 1fr;
        margin: 0;
        p {
          text-align: left;
        }
        &_form {
          button {
            height: 6rem;
            a p {
              text-align: center;
            }
          }
        }
      }
    }
}
`;

export default Contact