import React from 'react';
import styled from 'styled-components';
import service1 from '../assets/service1.png';
import service2 from '../assets/service2.png';
import service3 from '../assets/service3.png';
import Title from './Title';

function Services() {
  const data = [
    {
      type: "Braids",
      text: "We use a variety of techniques to create stunning, intricate patterns that will leave you feeling confident and gorgeous.",
      image: service1,
    },
    {
      type: "Treatments",
      text: "Our signature hair treatment service harnesses the power of Olaplex and other top-quality products, including nourishing protein treatments, to rejuvenate and strengthen your hair.",
      image: service2,
    },
    {
      type: "Twists",
      text: "We bring to you a bouquet of services to remain worry free about your looks.",
      image: service3,
    },
  ]
  return <Section id='services'>
    <Title value="services" />
    <div className="services">
      {data.map(({type,text,image},index) => {
          return (
            <div className="services_service">
              <div className="services_service_image">
                <img src={image} alt="Service" />
              </div>
              <div className="services_service_title">
                <span>{index + 1}</span>
                <h2>{type}</h2>
              </div>
              <p className="services_service_description">{text}</p>
            </div>
          )
        })}
    </div>
  </Section>
  
}

const Section = styled.section`
min-height: 100vh;
.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 100%;
  margin: 0 14rem;
  margin-top: 10rem;
  gap: 5rem;
  &_service {
    padding: 2rem;
    &:nth-of-type(2) {
      background-color: var(--primary-color);
      .services_service_title {
        span {
          color: #fff;
        }
      }
      .services_service_description {
        color: #fff;
      }
    }
    &_title {
      span {
        color: var(--primary-color);
        font-weight: bolder;
      }
      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 5rem;
        color: var(--secondary-color);
      }
    }
    &_description {
      color: var(--primary-color);
      margin-bottom: 2rem;
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) {
   .services {
    margin: 2rem 0;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 2rem;
  }
}

`;

export default Services