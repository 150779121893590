import React, { useState } from 'react';
import styled from 'styled-components';


function Testimonials() {
  const [selected, setSelected] = useState(0);
  const testimonials = [
    {
    designation: "Tresses",
    name: "Jane Walker",
    review: "The attention to detail and expertise of the braiders at Tresses&Treatments is truly impressive. They listened to my preferences and executed the braids beautifully, exactly the way I wanted them."
    },
    {
    designation: "Tresses",
    name: "Laura",
    review: "I can't say enough about the fantastic experience I had at Tresses & Treatments! I recently got a weave done, and I'm absolutely thrilled with the results."
    },
    {
    designation: "Treatments",
    name: "Sharon",
    review: "The Olaplex treatment, combined with the protein and deep conditioning, worked wonders for my hair. It feels healthier, stronger, and incredibly soft. The difference was noticeable right away, and I've continued to see improvements over time."
    },
];

  return (
    <Section id='testimonials'>
      <div className="h-screen bg-cover container">
        <div className="testimonials">
          {testimonials.map(({ designation, name, review }, index)=> {
            return (
              <div className={`testimonial ${selected === index ? "active" : "hidden"}`} key={index}>
                <div className="title-container">
                  <span className="designation">
                    {designation}
                  </span>
                  <h3 className='title'>{name}</h3>
                </div>
                <p className="description">{review}</p>
              </div>
            );
          })}
        </div>
        <div className="controls">
          <button 
            className={selected === 0 ? "active" : ""} 
            onClick={() => {
             setSelected(0);
              }}>
          </button>
          <button 
            className={selected === 1 ? "active" : ""} 
            onClick={() => {
             setSelected(1);
              }}>
          </button>
          <button 
            className={selected === 2 ? "active" : ""} 
            onClick={() => {
             setSelected(2);
              }}>
          </button>
        </div>
      </div>
    </Section>
  )
}

const Section = styled.section`
overflow: hidden;

.container {
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  background-color: white;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  .testimonials {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
    width: 30%;
    .testimonial {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    .hidden {
      display: none;
    }
    color: #000000;
    .designation {
      height: 8rem;
    }
  }
  .controls {
    display: flex;
    gap: 1rem;
    button {
      padding: 0.5rem;
      border-radius: 1rem;
      background-color: var(--secondary-color);
      border: 0.1rem solid transparent;
      cursor: pointer;
    }
    .active {
      background-color: transparent;
      border: 0.1rem solid var(--secondary-color);
    }
  }
}

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .background {
      display: none;
    }
    .container {
      padding: 4rem 0;
      .testimonials {
        width: 80%;
        .testimonial {
          .description {
            height: 13rem;
          }
        }
      }
    }
  }
`;

export default Testimonials;