interface PlanCardProps {
    name: String;
    description: String;
    btnText?: String;
    price?: String;
    features: string[];
    color: String;
}

export default function PlanCard({
    name, 
    description, 
    price, 
    features, 
    color, 
    btnText = "Everything you want and more",
}: PlanCardProps) {
  return <div style={{backgroundColor: color}} className="flex min-h-[428px] w-[320px] flex-col rounded-3xl p-8">
    <h2 className="mb-5 text-xl font-medium">{name}</h2>
    <div className="mb-5 flex items-end text-2xl font-black">
        {price ? (
        <>
            <div>{price}</div>
        </>
        ) : (
            '£0'
        )}
    </div>
        <p className="mb-5">{description}</p>
        <ul className="mb-10 flex flex-col gap-y-2">
            {features.map((feature) => (
                <li className="flex items-center">
                    {feature}
                </li>
            ))}
        </ul>
        
  </div>;
}
