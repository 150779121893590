import React from 'react';
import styled from 'styled-components';
import home from "../assets/home.jpg";
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { homeAnimation, homeInfoAnimation } from './animation';


function Home() {
  return (
    <Section id='home'>
      <Navbar />
      <motion.div className="home"
      variants={homeAnimation}
      transition={{ delay: 0.3, duration: 0.6, type: "tween" }}
      >

        <div className="content">

          <div className="title">
            <h1>
              Tresses&Treatments
            </h1>
          </div>
          <div className="subTitle">
            <p>
              Step into our serene oasis and experience a world of luxury and rejuvenation,
              where every strand is treated with care and precision.  </p>
          </div>

        </div>

      </motion.div>

      <motion.div className="info"
      variants={homeInfoAnimation}
      transition={{ delay: 0.3, duration: 0.6, type: "tween" }}
      >
        <div className="grid">
          <div className="col">
            <strong>Parlour</strong>
            <p>Tresses & Treatments</p>
          </div>
          <div className="col">
            <strong>Contact</strong>
            <a href="mailto:tressesandtreatments@gmail.com">
              <p>Get In Touch</p>
            </a>
          </div>
          <div className="col">
            <strong>Mobile</strong>
            <p>+44 7392388138</p>
          </div>
          <div className="col">
            <strong>Address</strong>
            <p>22 Whitworth Road,</p>
            <p>Postcode: SE18 3QB.</p>
          </div>
          <div className="col">
            <strong>Services</strong>
            <p>Braids</p>
            <p>Olaplex</p>
          </div>
          <div className="col">
            <strong>Working Hours</strong>
            <p>Mon. Tue. Sat.</p>
            <p>9am to 8pm</p>
          </div>
        </div>
      </motion.div>
    </Section>
  )
}

const Section = styled.section`
  background: url(${home}) no-repeat center; 
  min-height: 100vh;
  background-size: cover;
  position: relative;
  .home{
    height: 100%;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
      color: #fff;
      gap:  1.2rem;
      margin-top; 8rem;
      padding-left: 18rem;
      .title {
        h1 {
          font-size: 4rem;
          line-height: 5.3rem;
        }
      }
      .subTitle {
        p {
          width: 80%;
          margin-bottom: 4rem;
        }
      }
    }
  }
  .info {
      position: absolute;
      bottom: -6rem;
      right: 0;
      background-color: var(--secondary-color);
      padding: 4rem;
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
        color: #fff;
        .col a
        {
          text-decoration: none;
          p {
    color: #fff;
  }
        }
      }
    }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .home {
      .content {
        padding-left: 2rem;
        width: 100%;
        margin-bottom: 2rem;
        .title {
          h1 {
            font-size: 2rem;
            line-height: 3rem;
          }
        }
      }
    }
    .info {
      position: initial;
      .grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  } 
`;

export default Home